.overlay_loading {
  z-index: 30;
  touch-action: none;
  /* 画面全体を覆う設定 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_loading{
  flex-flow: column;
}
.text{
  font-size: large;
  font-weight: bold;
  margin: 10px 0 5px;
  text-align: center;
}
.spinner {
  color: red;
}

/* layout */
.body {
  background-image: url('../../assets/images/background.jpg');
  object-fit: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
  font-size: 14px;
}
.body:before {
  content: '';
  background: inherit;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}
.body::after {
  content: '';
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}
.main {
  width: 90%;
  margin: 70px auto 0;
  min-height: calc(100vh - 120px);
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
}
.contents {
  max-width: 650px;
  margin: auto;
}

/* Header */
.header {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 90%;
  margin: auto;
  padding: 10px 0;
}
.logo {
  width: 30%;
}
.link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo_link {
  justify-content: left;
  margin-left: 30px;
}
.logo_icon {
  /* width: 120px; */
  width: 180px;
  height: 50px;
  display: flex;
}

@media (max-width: 710px) {
  .main {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .logo {
    width: 10%;
  }
  .logo_link {
    margin-left: 0;
  }
  .logo_icon {
    width: 50px;
    height: 50px;
  }
  .link {
    flex-flow: column;
    width: 50px;
  }
  .icon {
    height: 25px;
  }
  .contents {
    width: 90%;
    padding-top: 20px;
  }
}
