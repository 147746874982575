.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
}
.button:hover {
  opacity: 0.7;
}
.button:disabled {
  background-color: #b5b5b5;
}
.button:disabled:hover {
  opacity: 1;
}
.big {
  width: 100%;
}
.small {
  width: 170px;
}
.mini {
  width: auto;
  /* height: auto; */
  padding: 5px 20px;
}
.primary {
  background-color: #1d9bf0;
  color: #fff;
  border: unset;
}
.secondary {
  border: 1px solid #1d9bf0;
  color: #1d9bf0;
  border-radius: 5px;
  background-color: unset;
}
.gray {
  background-color: #b5b5b5;
  color: #fff;
  border: unset;
}
.transparent {
  background-color: transparent;
  color: #000;
  border: unset;
  padding: 0;
  justify-content: left;
}
.white{
  position: absolute;
  border-radius: 50%;
  padding: 0;
  z-index: 2;
  background-color: #fff;
}
.icon {
  width: 30px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_icon {
  max-width: 20px;
  max-height: 20px;
}

@media (max-width: 710px) {
  .mini {
    /* padding: 5px 10px; */
    margin: 0px;
  }
}
