.body {
  background-image: url('../../assets/images/background.jpg');
  object-fit: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
  font-size: 14px;
}
.body:before {
  content: '';
  background: inherit;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}
.body::after {
  content: '';
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}
.main {
  width: 90%;
  margin: 70px auto 0;
  min-height: calc(100vh - 120px);
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
}
.contents {
  max-width: 650px;
  margin: auto;
}

.is_hidden {
  visibility: hidden;
  opacity: 0;
}

.float_bottom{
  bottom: 50px;
}

@media (max-width: 710px) {
  .main {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .contents {
    width: 90%;
    padding: 10px 0;
  }
}