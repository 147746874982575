.modal_box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.modal {
  width: 400px;
  margin: auto;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0 1px 3px 0;
  background-color: #fff;
}
.icon {
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
.item_icon {
  width: 70px;
  height: 50px;
}
.text_box {
  display: flex;
}
.text {
  margin: 20px auto;
}
.flex {
  display: flex;
  margin-left: auto;
}
.button {
  width: 200px;
  cursor: pointer;
  padding: 10px 20px;
}
.yes_btn {
  background-color: unset;
  color: #808080;
  border: 1px solid #808080;
  margin: 10px auto;
}
.delete_yes {
  background-color: #f53649;
  color: #fff;
  border: unset;
}
.delete_no {
  color: #808080;
  border: 1px solid #808080;
  margin-right: 10px;
}

@media (max-width: 480px) {  
  .modal {
    width: 75%;
  }
}