.card {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.card_users{
  width: calc(100% - 120px);
}
.image {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-right: 20px;
}
.profile {
  margin-top: 5px;
  color: black;
}
.item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.profileName {
  width: 100px;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: center;
}
.items {
  width: calc(100% - 140px);
  margin-right: 20px;
}
.modal_items {
  width: 250px;
}
.gest{
  font-weight: bold;
  margin-top: 10px;
}
.item_name {
  line-break: anywhere;
}
.item_department{
  max-width: 60%;
}
.item_position{
  max-width: 40%;
  margin-left: 5px;
}
.item_position_only{  
  max-width: 40%;
}
.modal_item_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.name{
  font-size: 24px;
  margin-bottom: 0;
}
.usernameKana {
  margin: 0;
}
.hr{
  border-top: 1px solid #1d9bf0;
  margin: 10px 0;
}
.icon {
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_icon {
  max-width: 20px;
  max-height: 20px;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  max-width: none;
}
.edit{
  width: 100%;
}

@media (max-width: 710px) {
  .card_users {
    width: calc(100% - 60px);
  }
  .items {
    width: calc(100% - 120px);
    margin-right: 10px;
  }
  .image {
    width: 100px;
    margin-right: 10px;
  }
  .img {
    width: 80px;
    height: 80px;
  }
  .edit{
    margin-top: 10px;
  }
  .profileName {
    margin: 5px 0 0 0;
  }
}

@media (max-width: 480px) {
  .modal_items {
    width: calc(100% - 110px);
  }
  .icon{
    width: 35px;
  }
  .card_users {
    width: 100%;
  }
}
