.header {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 16px;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.wrap {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 10px 0;
}
.menu {
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_icon {
  max-width: 22px;
  max-height: 18px;
}
.sub_menu {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.create {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  background-color: #1d9bf0;
  padding: 5px 10px;
  border-radius: 5px;
}
.create:hover {
  opacity: 0.7;
}
.create:disabled:hover {
  opacity: 1;
}
.icon {
  width: 25px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_icon {
  max-width: 15px;
  max-height: 15px;
}
.menu_link {
  margin: 0 5px;
}
.menu_link:hover {
  opacity: 0.7;
}
.link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  margin-left: 5px;
}
.profile {
  margin: 0 30px 0 10px;
  cursor: pointer;
  position: relative;
  max-width: 45px;
  width: 30%;
}
.profile:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.img {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
}
.logo {
  display: flex;
  align-items: center;
  width: 40%;
}
.contact {
  color: #fff;
  margin-left: 10px;
  font-size: 12px;
}
.logo_link {
  justify-content: left;
  margin-left: 30px;
}
.logo_icon {
  /* width: 120px; */
  width: 180px;
  height: 50px;
  display: flex;
}

@media (max-width: 710px) {
  .link {
    flex-flow: column;
    max-width: 70px;
  }
  .logo_link {
    margin-left: 0;
    width: 50px;
  }
  .logo_icon {
    width: 50px;
    height: 50px;
  }
  .profile {
    margin: 0 0 0 auto;
  }
  .create {
    min-width: 85px;
    padding: 5px;
    margin-left: auto;
  }
  .menu_item {
    margin: 0 5px;
  }
  .menu_link {
    margin: 0;
  }
  .img {
    width: 45px;
  }
  .icon {
    height: 25px;
  }
  .sub_menu {
    width: 35%;
    justify-content: space-evenly;
  }
}
@media (max-width: 480px) {
  .wrap {
    font-size: 14px;
  }
  .logo {
    width: 35%;
  }
  .contact {
    margin-left: 10px;
  }
  .menu {
    justify-content: space-evenly;
  }
  .text {
    margin-left: 0;
  }
  .sub_menu {
    width: 35%;
    justify-content: space-evenly;
  }
  .create {
    min-width: 70px;
  }
  .profile {
    margin: 0 0 0 5px;
  }
}
