.auth {
  text-align: center;
}
.logo_icon {
  width: 250px;
  height: 50px;
  margin: 20px;
}
.text{
  line-height: 20px;
}