table {
  width: 100%;
  margin-bottom: 20px;
}
thead {
  border-bottom: 1px solid #b5b5b5;
  padding: 10px;
}
th p {
  font-weight: bold;
  padding: 5px;
  text-align: left;
}
/* td p {
  padding: 10px;
  text-align: left;
} */
/* td p {
  padding: 10px 5px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */
td p {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 40px;
}
.td{
  max-width: 0;
  overflow: hidden;
  padding: 10px 5px;
}
tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}
tbody tr:hover {
  background-color: rgba(29, 155, 240, 0.5);
  cursor: pointer;
}
.tr_no:hover {
  background-color: #f5f5f5;
  cursor: unset;
}
.td_no {
  padding: 30px;
  text-align: center;
}
.name_cell {
  width: 30%;
}
.datetime_cell {
  width: 20%;
}
.owner_cell {
  width: 16%;
}
.company_cell {
  width: 20%;
}
.consent_cell {
  width: 14%;
  text-align: center;
}
.unsolicited {
  display: inline-block;
  background-color: rgba(240, 114, 29, 0.5);
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin-top: 3px;
}

@media (max-width: 710px) {
  thead {
    display: none;
  }
  .tr {
    /* display: block; */
    position: relative;
    padding: 5px;
  }
  .td {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    overflow: hidden;
    max-width: unset;
  }
  .td p{
    -webkit-line-clamp: 1;
  }
  .item {
    float: left;
    width: 80px;
  }
  .item_value {
    width: 235px;
    padding: 0;
  }
  .name_cell p{
    width: calc(100% - 80px);
  }
  .name{
    font-size: 16px;
    font-weight: bold;
  }
  .consent {
    display: inline-table;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    text-align: right;
    margin: 5px;
  }
  .unsolicited {
    margin: 5px;
    text-align: center;
    width: 70px;
  }
  .icon {
    width: 15px;
    float: left;
    margin-right: 5px;
  }
  .owner_cell,
  .company_cell {
    width: 50%;
  }
  .owner_cell .item_value,
  .company_cell .item_value {
    width: 100%;
  }
}
