.card {
  display: flex;
  align-items: center;
  height: 35px;
  margin: 15px 0;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  padding: 0 35px;
  position: relative;
}
.card::before,.card::after {
  position: absolute;
  top: 50%;
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
}
.card::before {
  left: 0;
}
.card::after {
  right: 0;
}
.primary::before, .primary::after {
  background: #0099ff;
}
.secondary::before, .secondary::after {
  background: #f0721d;
}

@media (max-width: 480px) {
  .card{
    padding: 0 0 0 5px;
    border-left: 4px solid #0099ff;
    height: 30px;
    margin: 17px 0;
  }
  .card::before,.card::after {
    display: none;
  }
}