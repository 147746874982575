.footer {
  font-size: 14px;
}
.wrap {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
}
.item {
  width: calc( 100% / 3 );
  display: flex;
}
.logo{
  justify-content: left;
}
.home_logo{
  justify-content: center;
}
.contact{
  justify-content: center;
}
.contact a{
  color: #fff;
  text-decoration-line: none;
}
.copyright{
  justify-content: right;
  padding-right: 30px;
  color: rgba(255, 255, 255, 0.5);
}
.home_copyright{
  justify-content: center;
  padding-right: 0;

}
.logo_icon {
  height: 50px;
  padding: 10px 0;
  margin-left: 30px;
  display: flex;
}
.home_logo_icon{
  margin-left: 0;
}

@media (max-width: 710px) {
  .copyright{
    padding: 0;
  }
  .logo_icon {
    margin: 0;
  }
}
@media (max-width: 480px) {
  .wrap {
    display: block;
    padding: 5px 0;
  }
  .item{
    width: 100%;
    justify-content: center;
  }
  .contact, .copyright{
    margin-bottom: 5px;
  }
  .menu {
    margin-right: 5px;
    justify-content: space-evenly;
  }
  .sub_menu {
    width: 45%;
    justify-content: space-evenly;
  }
}
