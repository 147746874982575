.home {
  background-image: url('../../assets/images/background.jpg');
  object-fit: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-height: 100vh;
  color: #fff;
  font-size: 14px;
}
.home:before {
  content: '';
  background: inherit;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}
.home::after {
  content: '';
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
}

.main {
  width: 350px;
  min-height: calc( 100vh - 175px);
  margin: 50px auto;
}

.header {
  text-align: center;
  margin: 10px 0;
}
.title {
  font-size: 30px;
  font-weight: bold;
}
.logo_icon {
  /* width: 200px; */
  width: 250px;
  height: 50px;
}
.header_sub {
  display: inline-block;
  background-color: #1d9bf0;
  padding: 5px 15px;
  margin: 10px auto;
  border: 1px solid #fff;
  border-radius: 20px;
}
.header_text {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  margin: 10px 0 20px;
}
.text_sign_up{
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 20px;
}
.sign_in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.relative {
  position: relative;
  margin: 0px auto 5px;
}
.input {
  display: block;
  width: 350px;
  height: 45px;
  border-radius: 5px;
  border: unset;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 60px 0 45px;
}
.input_sign_in {
  padding: 0 5px 0 45px;
}
.icon {
  position: absolute;
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_icon {
  max-width: 27px;
  max-height: 27px;
}
.icon_meeting {
  position: unset;
  width: 100px;
  height: auto;
  margin: auto;
}
.label {
  position: absolute;
  width: 50px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 9px;
  right: 5px;
  border-radius: 5px;
}
.required {
  background-color: red;
}
.elective {
  background-color: #fff;
  color: #b5b5b5;
}
.error {
  color: red;
  margin: 10px 0;
  font-weight: bold;
}
.reset_password {
  margin: 5px 0 10px;
  text-align: center;
}
.link,
.link:visited,
.link:active {
  color: #fff;
}
.check_wrap {
  display: flex;
  margin: 15px 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: normal;
}
.check {
  width: 20px;
  height: 20px;
  float: left;
  transform: scale(1.2);
  cursor: pointer;
  margin-right: 10px;
}
.add_btn:disabled {
  cursor: no-drop;
}
.back_btn {
  border: unset;
  padding: 0;
  height: 30px;
}

.home_footer{
  flex-flow: column;
  height: 75px;
}
/* 準備中 */
.zyunbi {
  opacity: 0.6;
  cursor: no-drop;
}
