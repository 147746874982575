.menu_back {
  z-index: 1000;
  position: absolute;
  padding: 500% 500%;
  margin: -500% -500%;
  background-color: transparent;
}

.menu {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  position: absolute;
  top: 67px;
  right: 67px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0 1px 3px 0;
  color: black;
  z-index: 1001;
}
.profile_name {
  width: 390px;
  font-size: 18px;
  margin: 10px 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card {
  border: 1px solid #b5b5b5;
  padding: 10px;
  margin: 10px 10px 20px;
  border-radius: 5px;
}
.profile {
  width: 380px;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
.profile_names {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img {
  width: 27px;
  height: 27px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}
.icon {
  width: 17px;
  height: 17px;
  margin: 0 15px 0 5px;
}
.wrap {
  display: flex;
  align-items: center;
  margin: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

@media (max-width: 710px) {
  hr {
    margin: 5px 0;
  }
  .menu {
    max-height: calc(100vh - 80px);
    margin-top: 0px;
    right: 22px;
  }
  .profile_name {
    width: 100%;
  }
  .profile {
    width: calc(100% - 20px);
    height: 45px;
    margin: 0 10px;
  }
  .wrap {
    height: 45px;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  .menu{
    width: 90%;
  }
}