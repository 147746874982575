.float {
  margin: 0;
  top: auto;
  right: 20px;
  bottom: 10px;
  left: auto;
  position: fixed;
}
.float_button {
  display: flex;
  position: relative;
  background: #f0721d;
  border-radius: 30px !important;
  padding: 12px 15px 12px 25px;
  color: #fff;
  text-decoration: unset;
  box-shadow: rgb(0 0 0 / 50%) 0 1px 3px 0;
}
.icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.delete {
  color: #f0721d;
  border: 1px solid #f0721d;
  right: -12px;
  top: -12px;
  width: 30px;
  height: 30px;
  font-size: 20px;
}

@media (max-width: 710px) {
  .delete {
    top: -5px;
  }
}
@media (max-width: 480px) {
  .float {
    bottom: 0px;
  }
  .delete {
    top: -2px;
  }
}